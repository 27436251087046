import React, { useCallback, useState } from "react";

function CrudForm({ data, setdata, ElementForm, setLoading, visible }) {
  const HandleChange = useCallback(
    (value, id) => {
      setdata({
        ...data,
        [id]: value,
      });
    },
    [data, setdata]
  );

  let handleGetImages = (event) => {
    let files = event.target.files;
    setdata({ ...data, Image: files[0], ImagePath: "" });
  };
  let handleRemoveImage = useCallback(() => {
    setdata({ ...data, Image: "", ImagePath: "" });
  }, [data, setdata]);

  return (
    <div style={{ width: "100%" }}>
      <ElementForm
        data={data}
        handleRemoveImage={handleRemoveImage}
        handleGetImages={handleGetImages}
        HandleChange={HandleChange}
        setLoading={setLoading}
        visible={visible}
      />
    </div>
  );
}

export default CrudForm;
