import React from "react";
import { Card, Col, Row } from "antd";
import Meta from "antd/lib/card/Meta";
import { useTranslation } from "react-i18next";
import ComponentHeader from "../../../components/ComponentHeader/ComponentHeader";
import { ApiBaseUrl } from "../../../services/config";
import placeholder from "../../../assets/placeholder.png";
function CEO({ details, data }) {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <>
        <div id="feature" className="block featureBlock bgGray">
          <div className="container-fluid">
            <ComponentHeader
              {...{
                Title: "المديرين التنفيذيين ",
                TitleEn: "CEO's",
                Description: "",
                DescriptionEn: "",
              }}
            />
            <Row justify="center" gutter={[16, 16]}>
              {data?.map((ele) => (
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: data.length < 1 ? 12 : 8 }}
                >
                  <Card
                    hoverable
                    cover={
                      <img
                        src={
                          ele?.ImagePath
                            ? ApiBaseUrl + ele?.ImagePath
                            : placeholder
                        }
                        width="100%"
                        //  src={ele.Image}
                        height="100%"
                      />
                    }
                  >
                    <Meta
                      title={
                        i18n.language === "en"
                          ? ele.MemberNameEn
                          : ele.MemberName
                        //   i18n.language === "en" ? ele.TitleEn : ele.Title
                      }
                      description={
                        i18n.language === "en" ? ele.NoteEn : ele.Note
                        // i18n.language === "en"
                        //   ? ele.DescriptionEn
                        //   : ele.Description
                      }
                      style={{
                        direction: i18n.language === "en" ? "ltr" : "rtl",
                        textAlign: "center",
                      }}
                    />
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </>
    </div>
  );
}

export default React.memo(CEO);
