import { memo, useState } from "react";
import useCollapse from "react-collapsed";
import { useTranslation } from "react-i18next";
import "./CollapsibleElement.css";

// "fas fa-filter "
const CollapsibleElement = ({
  children,

  Component,
}) => {
  const { t, i18n } = useTranslation();
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();
  return (
    <div>
      <div {...getToggleProps()}>{Component && <Component />}</div>
      <div {...getCollapseProps()}>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default memo(CollapsibleElement);
