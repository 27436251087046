import placeholder from "./assets/placeholder.png";
export const homeCategories = [
  {
    Id: 1,
    ImagePath: placeholder,
    Title: "عنوان",
    TitleEn: "TitleEn",
    Description: "وصف",
    DescriptionEn: "DescriptionEn",
    fullWidth: false,
  },
  {
    Id: 1,
    ImagePath: placeholder,
    Title: "عنوان",
    TitleEn: "TitleEn",
    Description: "وصف",
    DescriptionEn: "DescriptionEn",
    FullWidth: false,
  },
  {
    Id: 1,
    ImagePath:
      "https://www.bigheartsoutdoors.org/wp-content/uploads/2019/10/Rectangle-1920x1080-Placeholder.png",
    Title: "عنوان",
    TitleEn: "TitleEn",
    Description: "وصف",
    DescriptionEn: "DescriptionEn",
    FullWidth: true,
  },
  {
    Id: 1,
    ImagePath: placeholder,
    Title: "عنوان",
    TitleEn: "TitleEn",
    Description: "وصف",
    DescriptionEn: "DescriptionEn",
    fullWidth: false,
  },
  {
    Id: 1,
    ImagePath: placeholder,
    Title: "عنوان",
    TitleEn: "TitleEn",
    Description: "وصف",
    DescriptionEn: "DescriptionEn",
    FullWidth: false,
  },
  {
    Id: 1,
    ImagePath:
      "https://www.bigheartsoutdoors.org/wp-content/uploads/2019/10/Rectangle-1920x1080-Placeholder.png",
    Title: "عنوان",
    TitleEn: "TitleEn",
    Description: "وصف",
    DescriptionEn: "DescriptionEn",
    FullWidth: true,
  },
  {
    Id: 1,
    ImagePath: placeholder,
    Title: "عنوان",
    TitleEn: "TitleEn",
    Description: "وصف",
    DescriptionEn: "DescriptionEn",
    fullWidth: false,
  },
  {
    Id: 1,
    ImagePath: placeholder,
    Title: "عنوان",
    TitleEn: "TitleEn",
    Description: "وصف",
    DescriptionEn: "DescriptionEn",
    FullWidth: false,
  },
  {
    Id: 1,
    ImagePath:
      "https://www.bigheartsoutdoors.org/wp-content/uploads/2019/10/Rectangle-1920x1080-Placeholder.png",
    Title: "عنوان",
    TitleEn: "TitleEn",
    Description: "وصف",
    DescriptionEn: "DescriptionEn",
    FullWidth: true,
  },
];
export const categories = [
  {
    Id: 1,
    ImagePath: placeholder,
    Title: "عنوان",
    TitleEn: "TitleEn",
    Description: "وصف",
    DescriptionEn: "DescriptionEn",
    fullWidth: false,
  },
  {
    Id: 1,
    ImagePath: placeholder,
    Title: "عنوان",
    TitleEn: "TitleEn",
    Description: "وصف",
    DescriptionEn: "DescriptionEn",
    FullWidth: false,
  },
  {
    Id: 1,
    ImagePath:
      "https://www.bigheartsoutdoors.org/wp-content/uploads/2019/10/Rectangle-1920x1080-Placeholder.png",
    Title: "عنوان",
    TitleEn: "TitleEn",
    Description: "وصف",
    DescriptionEn: "DescriptionEn",
    FullWidth: false,
  },
  {
    Id: 1,
    ImagePath:
      "https://www.bigheartsoutdoors.org/wp-content/uploads/2019/10/Rectangle-1920x1080-Placeholder.png",
    Title: "عنوان",
    TitleEn: "TitleEn",
    Description: "وصف",
    DescriptionEn: "DescriptionEn",
    FullWidth: false,
  },
  {
    Id: 1,
    ImagePath:
      "https://www.bigheartsoutdoors.org/wp-content/uploads/2019/10/Rectangle-1920x1080-Placeholder.png",
    Title: "عنوان",
    TitleEn: "TitleEn",
    Description: "وصف",
    DescriptionEn: "DescriptionEn",
    FullWidth: false,
  },
];
export const item = [
  {
    Id: 1,
    ImagePath: placeholder,
    Title: "عنوان",
    TitleEn: "TitleEn",
    Description: "وصف",
    DescriptionEn: "DescriptionEn",
    fullWidth: false,
  },
  {
    Id: 1,
    ImagePath: placeholder,
    Title: "Title",
    TitleEn: "TitleEn",
    Description: "Description",
    DescriptionEn: "DescriptionEn",
    FullWidth: false,
  },
  {
    Id: 1,
    ImagePath:
      "https://www.bigheartsoutdoors.org/wp-content/uploads/2019/10/Rectangle-1920x1080-Placeholder.png",
    Title: "عنوان",
    TitleEn: "TitleEn",
    Description: "وصف",
    DescriptionEn: "DescriptionEn",
    FullWidth: false,
  },
  {
    Id: 1,
    ImagePath:
      "https://www.bigheartsoutdoors.org/wp-content/uploads/2019/10/Rectangle-1920x1080-Placeholder.png",
    Title: "عنوان",
    TitleEn: "TitleEn",
    Description: "وصف",
    DescriptionEn: "DescriptionEn",
    FullWidth: false,
  },
  {
    Id: 1,
    ImagePath:
      "https://www.bigheartsoutdoors.org/wp-content/uploads/2019/10/Rectangle-1920x1080-Placeholder.png",
    Title: "عنوان",
    TitleEn: "TitleEn",
    Description: "وصف",
    DescriptionEn: "DescriptionEn",
    FullWidth: false,
  },
];
export const branchs = [
  {
    Id: 1,
    Country: "ليبيا",
    City: "طرابلس",
    Address:
      "منطقة الدهماني – شارع مستشفى العيـــــون- بجــــوار السفـــارة التركيــــة",
    CountryEn: "Libya",
    CityEn: "Tripoli",
    AddressEn: "Zawiat Al Dahmani",
    Numbers: [
      {
        Id: 1,
        PhoneNumber: "0213404605",
        PhoneNumberEn: "0213404605",
        Active: false,
        BranchId: 1,
      },
      {
        Id: 2,
        PhoneNumber: "0915296954",
        PhoneNumberEn: "0915296954",
        Active: false,
        BranchId: 1,
      },
      {
        Id: 3,
        PhoneNumber: "0915296841",
        PhoneNumberEn: "0915296841",
        Active: false,
        BranchId: 1,
      },
    ],
    Active: true,
  },
];
