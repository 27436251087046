import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";

import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import logo from "../../assets/images/logo.svg.svg";
import "./header.css";
import CollapsibleElement from "../CollapsibleElement/CollapsibleElement";
function AppHeader({ categories }) {
  let history = useHistory();
  const { t, i18n } = useTranslation();
  const [langue, setlangue] = useState("AR");
  useEffect(() => {
    setlangue(i18n.language === "en" ? "Ar" : "En");
  }, [i18n.language]);
  const changeLanguage = useCallback(
    (e) => {
      //   setlangue(i18n.language === "en" ? "Ar" : "En");
      i18n.changeLanguage(i18n.language === "en" ? "ar" : "en");
      window.scrollTo(0, 0);
    },
    [i18n]
  );

  const [scrollPosition, setPosition] = useState(0);

  useLayoutEffect(() => {
    function updatePosition() {
      if (window.pageYOffset > 80) {
        //  document.getElementById("navbar").style.padding = "0px 0px";
        console.log("hide");
        document.getElementById("subtitle").style.display = "none";
        document.getElementsByClassName("title-header")[0].style.display =
          "none";
        document.getElementsByClassName("title-header")[1].style.display =
          "none";
        document.getElementById("main-header").style.height = "80px";
      }
      if (window.pageYOffset < 80) {
        //   document.getElementById("navbar").style.padding = "10px 0px";
        console.log("show");
        document.getElementById("subtitle").style.display = "block";
        document.getElementsByClassName("title-header")[1].style.display =
          "block";
        document.getElementsByClassName("title-header")[1].style.display =
          "block";
        document.getElementById("main-header").style.height = "90px";
      }
    }
    window.addEventListener("scroll", updatePosition);
    updatePosition();
    return () => window.removeEventListener("scroll", updatePosition);
  }, []);
  const Logo = () => (
    <div class="pro-arrow" style={{ direction: "ltr" }}>
      <a style={{ position: "relative", top: "-10px" }}>
        <img
          onClick={() => history.push("/")}
          src={logo}
          width="40px"
          height={"40px"}
        />
        <p
          rel="home"
          id="subtitle"
          onClick={() => history.push("/")}
          className="title-header"
          style={{
            color: "black",
            fontFamily: i18n.language == "en" && "monotest",
            margin: 0,
            cursor: "pointer",
          }}
        >
          {i18n.language == "en" ? "Riyadh pack. Factory" : "مصنع علب الرياض"}
        </p>
        <div
          className="on-hover-underline langue-text"
          onClick={changeLanguage}
        >
          {langue}
        </div>
      </a>
    </div>
  );
  const MenuToggler = () => (
    <input type="checkbox" id="active" className="header-checkbox" />
  );
  const DropDownCategories = ({ type }) => (
    <div>
      <ul class="dropdown-content sub-menu">
        {categories?.map(
          (ele) =>
            ele.type == type && (
              <li>
                <div className="header-drop-down-elements">
                  <Link
                    style={{
                      fontFamily: i18n.language == "en" && "monotest",
                    }}
                    className={
                      "on-hover-underline incategories-text cat-title" +
                      (i18n.language == "ar" ? " arabic-title" : "")
                    }
                  >
                    {i18n.language === "en" ? ele.TitleEn : ele.Title}
                  </Link>
                </div>
                {ele.Categories?.map((elec) => (
                  <div
                    className=" "
                    style={{
                      fontSize: "15px",
                    }}
                  >
                    <Link
                      to={"/category/" + elec.Id}
                      className="on-hover-underline incategories-text categ-title"
                    >
                      {i18n.language === "en" ? elec.TitleEn : elec.Title}
                    </Link>
                  </div>
                ))}
              </li>
            )
        )}
      </ul>
    </div>
  );
  const DropDownCategoriesMobile = ({ type }) => (
    <div>
      {categories?.map(
        (ele) =>
          ele.type == type && (
            <>
              <div className="header-drop-down-elements">
                <Link
                  style={{
                    fontFamily: i18n.language == "en" && "monotest",
                    color: "white",
                  }}
                  className="on-hover-underline incategories-text cat-title"
                >
                  {i18n.language === "en" ? ele.TitleEn : ele.Title}
                </Link>
              </div>
              {ele.Categories?.map((elec) => (
                <div className=" ">
                  <Link
                    to={"/category/" + elec.Id}
                    className="on-hover-underline incategories-text categ-title"
                  >
                    {i18n.language === "en" ? elec.TitleEn : elec.Title}
                  </Link>
                </div>
              ))}
            </>
          )
      )}
    </div>
  );
  return (
    <nav
      id="navbar"
      className="site-nav"
      style={{
        direction: i18n.language === "ar" ? "rtl" : "ltr",
        height: "inherit",
      }}
    >
      <div
      // className="header"
      >
        <div className="mobileHidden">
          <div className="masthead">
            <ul className={"main-nav-list "}>
              <li class="dropdown dropbtn">
                <div>
                  <a
                    className="on-hover-underline "
                    style={{
                      fontFamily: i18n.language == "en" && "monotest",
                    }}
                  >
                    {t("Packaging")}
                  </a>
                  <DropDownCategories type={1} />
                </div>
              </li>
              <li>
                <div class="dropdown">
                  <a
                    className="on-hover-underline dropbtn"
                    style={{
                      fontFamily: i18n.language == "en" && "monotest",
                    }}
                  >
                    {t("Production")}
                  </a>
                  <DropDownCategories type={2} />
                </div>
              </li>
              <li>
                <Logo />
              </li>
              <li>
                <Link
                  style={{
                    fontFamily: i18n.language == "en" && "monotest",
                  }}
                  to={"/about-us"}
                  className="on-hover-underline"
                >
                  {t("About us")}
                </Link>
              </li>
              <li>
                <Link
                  style={{
                    fontFamily: i18n.language == "en" && "monotest",
                  }}
                  to={"/contact-us"}
                  className="on-hover-underline"
                >
                  {t("Contact")}{" "}
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="mobileVisible">
          <div style={{ position: "relative", top: "14px" }}>
            <Logo />
          </div>
          <MenuToggler />
          <label for="active" class="menu-btn">
            <span></span>
          </label>
          <label for="active" class="close"></label>
          <div class="wrapper-user-menu">
            <div className="wrapper-content">
              <ul>
                <li style={{ padding: "10px" }}>
                  <MenuToggler />
                  <Link
                    style={{
                      fontFamily: i18n.language == "en" && "monotest",
                    }}
                    className="on-hover-underline"
                    to={"/"}
                  >
                    {t("Home")}
                  </Link>
                </li>

                <li>
                  <CollapsibleElement
                    Component={() => (
                      <Link
                        style={{
                          fontFamily: i18n.language == "en" && "monotest",
                          fontSize: "38px",
                        }}
                        className="on-hover-underline"
                      >
                        {t("Packaging")}
                      </Link>
                    )}
                  >
                    <DropDownCategoriesMobile type={1} />
                  </CollapsibleElement>
                </li>
                <li>
                  <CollapsibleElement
                    Component={() => (
                      <Link
                        style={{
                          fontFamily: i18n.language == "en" && "monotest",
                          fontSize: "38px",
                        }}
                        className="on-hover-underline"
                      >
                        {t("Production")}
                      </Link>
                    )}
                  >
                    <DropDownCategoriesMobile type={2} />
                  </CollapsibleElement>
                </li>
                <li>
                  <MenuToggler />
                  <Link
                    style={{
                      fontFamily: i18n.language == "en" && "monotest",
                    }}
                    className="on-hover-underline"
                    to={"/about-us"}
                  >
                    {t("About us")}
                  </Link>
                </li>
                <li>
                  <MenuToggler />
                  <Link
                    style={{
                      fontFamily: i18n.language == "en" && "monotest",
                    }}
                    className="on-hover-underline"
                    to={"/contact-us"}
                  >
                    {t("Contact")}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default AppHeader;
