import ReactGA from "react-ga";

export const initGA = () => {
  ReactGA.initialize("G-HRR69L1E10");
};

export const logPageView = () => {
  ReactGA.set({ page: window.location.pathname }); // Update the user's current page
  ReactGA.pageview(window.location.pathname + window.location.search); // Record a pageview for the given page
};
