import React from "react";
import { ApiBaseUrl } from "../../../services/config";
import { Card, Col, Row } from "antd";
import Meta from "antd/lib/card/Meta";
import { useTranslation } from "react-i18next";
import ComponentHeader from "../../../components/ComponentHeader/ComponentHeader";
import placeholder from "../../../assets/placeholder.png";
const AppAbout = ({
  data,
  section,
  details = "lorem ipsum dolor sit amet, consectetur adipiscing elit",
}) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <div id="/home#about" className="block aboutBlock ">
        <div className="container-fluid">
          <ComponentHeader
            {...{
              Title: "ماذا نصنع؟",
              TitleEn: "What we make?",
              Description: "",
              DescriptionEn: "",
            }}
          />

          <div className="contentHolder">
            <p>
              {section
                ? i18n.language === "en"
                  ? section.ContentEn
                  : section.Content
                : null}
            </p>
          </div>
          <Row
            gutter={[16, 16]}
            justify="center"
            className="animate__animated animate__zoomIn"
          >
            {data?.map((item, index) => {
              return (
                <Col md={{ span: 8 }} key={item.key}>
                  <Card
                    hoverable={true}
                    cover={
                      <img
                        src={
                          item?.ImagePath
                            ? ApiBaseUrl + item?.ImagePath
                            : placeholder
                        }
                        // src={item?.ImagePath}
                        width="100%"
                        height="100%"
                      />
                    }
                  >
                    <Meta
                      title={i18n.language === "en" ? item.TitleEn : item.Title}
                      description={
                        i18n.language === "en"
                          ? item.DescriptionEn
                          : item.Description
                      }
                      style={{
                        direction: i18n.language === "en" ? "ltr" : "rtl",
                      }}
                    />
                  </Card>
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
    </>
  );
};

export default React.memo(AppAbout);
