import React, { useCallback } from "react";
import { Popup } from "devextreme-react/popup";
import ScrollView from "devextreme-react/scroll-view";
import SubmitButtons from "../Buttons/SubmitButtons";
function PopUpModel({
  maxWidth = 1000,
  setdialog,
  dailog,
  children,
  Submit,
  loading,
  title,
}) {
  return (
    <div>
      <Popup
        maxWidth={maxWidth}
        title={title}
        minWidth={150}
        minHeight={500}
        showTitle={true}
        dragEnabled={false}
        closeOnOutsideClick={true}
        visible={dailog}
        fullScreen
        onHiding={() => setdialog(false)}
      >
        {!loading ? (
          <ScrollView showScrollbar="onHover">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                Submit();
              }}
            >
              {children}
              {Submit ? (
                <div className="col-lg-3 col-sm-6 col-sm-12">
                  <SubmitButtons />
                </div>
              ) : null}
            </form>
          </ScrollView>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              color: "black",
            }}
          >
            <div
              className="loader_x_form spinner-border "
              style={{
                color: "black",
              }}
              role="status"
            >
              <span
                style={{
                  color: "black",
                }}
                className="sr-only"
              >
                Loading...
              </span>
            </div>
          </div>
        )}
      </Popup>
    </div>
  );
}

export default PopUpModel;
