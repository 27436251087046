import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { ITEMS_USER_BY_ID } from "../../Admin/Items/Items.Api";
import ComponentHeader from "../../components/ComponentHeader/ComponentHeader";
import ElementCard from "../../components/ElementCard/ElementCard";
import Loader from "../../components/Loader/Loader";
import ThreeDModel from "../../components/ThreeDModel/ThreeDModel";
const Item = ({}) => {
  const { t, i18n } = useTranslation();
  useEffect(() => {}, []);

  const [data, setdata] = useState({});
  let { id } = useParams();
  const [loading, setloading] = useState(false);
  useEffect(async () => {
    window.scrollTo(0, 0);
    setloading(true);
    ITEMS_USER_BY_ID(id)
      .then((res) => {
        setdata(res);
      })
      .catch(() => {})
      .finally((e) => setloading(false));
  }, [id]);
  let history = useHistory();

  return (
    <>
      <div
        className="main"
        style={{ direction: i18n.language === "ar" ? "rtl" : "ltr", margin: 0 }}
      >
        <Loader loading={loading} />
        <div
          className="row"
          style={{
            margin: 0,
          }}
        >
          <div
            className={
              "block_wrap " +
              (data?.ItemFiles &&
              data?.ItemFiles.length > 0 &&
              !data?.ItemFiles[0].FullWidth
                ? "half"
                : "full")
            }
            style={{ minHeight: "300px" }}
          >
            <ComponentHeader {...data} />
            <div style={{ display: "flex", justifyContent: "center" }}>
              <ThreeDModel modelPath={data.ItemModel} />
            </div>
          </div>

          {data?.ItemFiles?.map((e) => (
            <ElementCard {...e} />
          ))}
        </div>
      </div>
    </>
  );
};

export default Item;
