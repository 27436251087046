import { Card, Col, Row } from "antd";
import Meta from "antd/lib/card/Meta";
import React from "react";
import { ApiBaseUrl } from "../../../services/config";
import { useTranslation } from "react-i18next";
import ComponentHeader from "../../../components/ComponentHeader/ComponentHeader";
import placeholder from "../../../assets/placeholder.png";
function Service({ details, data }) {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <>
        <div id="/home#service" className="block featureBlock bgGray">
          <div className="container-fluid">
            <ComponentHeader
              {...{
                Title: "مسؤوليتنا",
                TitleEn: "Our responsibility",
                Description: "",
                DescriptionEn: "",
              }}
            />

            <Row justify="center" gutter={[16, 16]}>
              {data?.map((ele) => (
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: data.length < 1 ? 12 : 8 }}
                >
                  <Card
                    hoverable={true}
                    onClick={() => window.open(ele?.Link, "_blank").focus()}
                    cover={
                      <img
                        src={
                          ele?.ImagePath
                            ? ApiBaseUrl + ele?.ImagePath
                            : placeholder
                        }
                        //   src={ele?.ImagePath}
                        width="100%"
                        height="100%"
                      />
                    }
                    style={{
                      direction: i18n.language === "ar" ? "rtl" : "ltr",
                    }}
                  >
                    <Meta
                      title={i18n.language === "en" ? ele.TitleEn : ele.Title}
                      description={
                        i18n.language === "en"
                          ? ele.DescriptionEn
                          : ele.Description
                      }
                    />
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </>
    </div>
  );
}

export default Service;
