import Layout, { Content, Footer, Header } from "antd/lib/layout/layout";
import "devextreme/dist/css/dx.light.css";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Route, Switch } from "react-router-dom";
import "../App.css";
import AppFooter from "../components/common/footer";
import AppHeader from "../components/common/header";
import ScrollToTop from "../components/ScrollTop";
import { ALL, COMMON } from "../services/SharedApi/Section.Api";
import Category from "../views/category/Category";
import Home from "../views/User/Home/Home";
import Item from "../views/item/Item";
import ContactUs from "../views/contactUs/ContactUs";
import AboutUs from "../views/AboutUs/AboutUs";
import Loader from "../components/Loader/Loader";
import Categories from "../views/categories/Categories";
import FloatingButton from "../components/FloatingButton/FloatingButton";

function UserLayout() {
  const { t, i18n } = useTranslation();
  if (i18n.language === "en-US") {
    i18n.init();
    document.documentElement.setAttribute("lang", "en");
    i18n.changeLanguage("en");
  }
  useEffect(() => {
    document.scrollingElement && (document.scrollingElement.scrollTop = 0);
  }, []);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    COMMON()
      .then(setData)
      .finally(() => setLoading(false));
  }, []);
  return (
    <div className="App user-panel">
      <Layout className="mainLayout">
        <Header id="main-header" style={{ zIndex: 10 }}>
          <AppHeader categories={data.SuperCategories} />
        </Header>
        <div style={{ minHeight: "500px", marginTop: "120px" }}>
          <Content>
            <Loader loading={loading} />
            <ScrollToTop />
            <FloatingButton />
            <Switch>
              <Route path="/home" exact component={Home} />
              <Route path="/category/:id" exact component={Category} />
              <Route path="/super-category/:id" exact component={Category} />
              <Route path="/categories" exact component={Categories} />
              <Route path="/item/:id" exact component={Item} />
              <Route path="/contact-us" exact component={ContactUs} />
              <Route path="/about-us" exact component={AboutUs} />
              {/*   <Route path="/post/:post" exact component={Posts} />
              <Route path="/postDetails/:id" exact component={PostsDetails} />*/}
              <Redirect to="/home" />
            </Switch>
          </Content>
        </div>

        <Footer>
          <AppFooter
            data={data?.Social?.filter((e) => e.Type !== "Video")}
            superCategories={data.SuperCategories}
          />
          <hr />© 2022 Progress Packaging Ltd.
        </Footer>
      </Layout>
    </div>
  );
}

export default UserLayout;
