import React from "react";

import { CheckBox } from "devextreme-react/check-box";
import withLabel from "./withLabel";
import { memo } from "react";

const Input = ({
  label,
  value = false,
  name,
  handleChange,
  readOnly = false,
}) => {
  return (
    <CheckBox
      value={value}
      style={{ direction: "ltr" }}
      id={name}
      name={name}
      onValueChanged={({ value }) => handleChange(value, name)}
      readOnly={readOnly}
    />
  );
};
//(e) => onValueChange(e.target.value, e.target.id)
export default memo(withLabel(Input));
