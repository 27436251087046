import React, { useCallback, useEffect, useState } from "react";
import { FormGroup } from "reactstrap";
import SubmitButtons from "../../../components/Buttons/SubmitButtons";
import CardForm from "../../../components/CardForm/CardForm";
import InputTwoLanguages from "../../../components/InputTwoLanguages/InputTwoLanguages";
import { SECTION, SECTION_UPDATE } from "../../FAQ/API";
import notify from "devextreme/ui/notify";
function Section() {
  const [loading, setloading] = useState(false);
  let [data, setdata] = useState({ Text: [{}, {}] });
  useEffect(async () => {
    setloading(true);
    SECTION("AboutUs")
      .then((res) => {
        setdata(res[0]);
      })
      .catch(() => {})
      .finally(() => setloading(false));
  }, []);
  const HandleChange = useCallback(
    (value, id, lan) => {
      setdata({
        ...data,
        Text: data.Text?.map((item) =>
          id === item.Id
            ? lan.toString().includes("En")
              ? { ...item, ContentEn: value }
              : { ...item, Content: value }
            : { ...item }
        ),
      });
    },
    [data]
  );

  const OnSubmit = useCallback(
    async (id) => {
      setloading(true);
      await SECTION_UPDATE(data)
        .then((res) => {
          notify("Updated successfuly", "success", 3000);
        })
        .catch(() => {
          notify("Error in information. try again! ", "error", 3000);
        })
        .finally(() => setloading(false));
    },
    [data]
  );
  return (
    <div style={{ width: "100%" }}>
      <CardForm title=" Section" loading={loading}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            OnSubmit();
          }}
        >
          <FormGroup>
            <InputTwoLanguages
              id="Content"
              label={"Description"}
              onValueChange={(value, id) => HandleChange(value, 5, id)}
              value={data?.Text[0]?.Content}
              valueEn={data?.Text[0]?.ContentEn}
            />
          </FormGroup>
          <SubmitButtons onClick={OnSubmit} type={"button"} />
        </form>
      </CardForm>
    </div>
  );
}

export default Section;
