import React from "react";

import { useTranslation } from "react-i18next";
import img2 from "./../../assets/acc.jpg";
import { Link } from "react-router-dom";
import { useRef } from "react";
function AppFooter({ data, superCategories = [] }) {
  const About = useRef([
    {
      title: "What we make?",

      route: "/about-us",
    },
    {
      title: "Our responsibility",

      route: "/about-us",
    },
    {
      title: "CEO's",

      route: "/about-us",
    },

    {
      title: "Partners",

      route: "/about-us",
    },
    {
      title: "Frequently Asked Questions",

      route: "/about-us",
    },
    {
      title: "Packaging",

      route: "/categories",
    },
    {
      title: "Contact",

      route: "/contact-us",
    },
  ]);
  const { t, i18n } = useTranslation();
  return (
    <div className="container-fluid">
      <div className="footer">
        <div
          className="row "
          style={{ direction: i18n.language === "ar" ? "rtl" : "ltr" }}
        >
          <div className="col-lg-6 col-md-6 col-md-6">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div
                  className="header-text "
                  style={{
                    fontFamily: i18n.language == "en" && "monotest",
                  }}
                >
                  {t("About us")}
                </div>
                <div className="footer-content">
                  <ul style={{ listStyle: "none", padding: 0 }}>
                    {About.current.map((e, index) => (
                      <li
                        key={index}
                        className="on-hover-underline-increase"
                        style={{ width: "100%", padding: "4px 0" }}
                      >
                        <Link style={{ color: "black" }} to={e.route}>
                          {t(e.title)}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div
                  className="header-text "
                  style={{
                    fontFamily: i18n.language == "en" && "monotest",
                  }}
                >
                  {t("Follow")}
                </div>
                <div>
                  <ul className="socials" style={{ padding: "10px 0" }}>
                    {data?.map((res, index) =>
                      res.Link ? (
                        <li key={index}>
                          <a
                            onClick={() =>
                              window.open(res.Link, "_blank").focus()
                            }
                            className={"on-hover-underline"}
                            style={{ fontSize: "25px" }}
                          >
                            <i className={res.Type}></i>
                          </a>
                        </li>
                      ) : null
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div style={{ minHeight: "100px" }}>
              <div
                className="header-text "
                style={{
                  fontFamily: i18n.language == "en" && "monotest",
                }}
              >
                {t("Contact us")}
              </div>
              <div className="footer-content" style={{ padding: "10px" }}>
                Sales@riyadhpack.com
              </div>
            </div>
            <div style={{ minHeight: "100px" }}>
              <div
                className="header-text "
                style={{
                  fontFamily: i18n.language == "en" && "monotest",
                }}
              >
                {t("Main Category")}
              </div>
              <div className="footer-content">
                <ul style={{ listStyle: "none", padding: 0 }}>
                  {superCategories?.map((e, index) => (
                    <li
                      key={index}
                      className="on-hover-underline-increase"
                      style={{ width: "100%", padding: "4px 0" }}
                    >
                      <Link
                        style={{ color: "black" }}
                        to={"/super-category/" + e.Id}
                      >
                        {i18n.language == "en" ? e.TitleEn : e.Title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div>
              <div
                className="header-text "
                style={{
                  fontFamily: i18n.language == "en" && "monotest",
                }}
              >
                {t("Accreditations")}
              </div>
              <div style={{ maxWidth: "400px", padding: "20px 0" }}>
                <img src={img2} width="100%" height={"100%"} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppFooter;
