import React from "react";
import { useTranslation } from "react-i18next";
import { FormGroup, Input, Row, Col } from "reactstrap";

const InputTwoLanguages = ({
  id,
  label,
  value,
  valueEn,
  enDisabled = false,
  maxLength = 10000000000000,
  DisabledAnotherLangue = false,
  onValueChange,
  height = 100,
}) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Row style={{ width: "100%" }}>
        <Col className="pr-1" md="6">
          <FormGroup>
            <label style={{ fontSize: "18px" }}>{t(label)}</label>
            <Input
              id={id}
              value={value}
              disabled={DisabledAnotherLangue}
              onChange={(e) => onValueChange(e.target.value, e.target.id)}
              type="textarea"
              maxLength={maxLength}
              style={{ fontSize: "18px", minHeight: height, direction: "rtl" }}
            />
          </FormGroup>
        </Col>
        <Col className="pr-1" md="6">
          <FormGroup style={{ fontSize: "18px" }}>
            <label style={{ fontSize: "18px" }}>{t(label)} English</label>
            <Input
              id={id + "En"}
              value={valueEn}
              disabled={enDisabled}
              onChange={(e) => onValueChange(e.target.value, e.target.id)}
              type="textarea"
              maxLength={maxLength}
              style={{ fontSize: "18px", minHeight: height }}
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default InputTwoLanguages;
