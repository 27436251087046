import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import ElementCard from "../../../../components/ElementCard/ElementCard";

const HomeCategories = ({ data }) => {
  const { t, i18n } = useTranslation();
  let history = useHistory();
  console.log(data);
  return (
    <>
      <div
        className="main"
        style={{ direction: i18n.language === "ar" ? "rtl" : "ltr" }}
      >
        <div className="row" style={{ margin: 0 }}>
          {data.map((e) => (
            <ElementCard
              {...e}
              onClick={() => history.push("/category/" + e.CategoryId)}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default React.memo(HomeCategories);
