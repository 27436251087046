import { useEffect, useRef, useState } from "react";
import HomeCategories from "./Components/HomeCategories/HomeCategories";
import PartnersCard from "./Components/Partnres/PartnersCard";
import PartnersForm from "./Components/Partnres/PartnersForm";
import "./Home.css";
import {
  INVENTORY,
  INVENTORY_DELETE,
  INVENTORY_INSERT,
  INVENTORY_ORDER,
  INVENTORY_UPDATE,
} from "../AboutUs/AboutUs.Api";
import CrudComponent from "../../components/CrudComponent/CrudComponent";
import { VISITORS } from "./Home.Api";

function Home() {
  const defaultValuesPartners = useRef({
    TitleEn: "",
    Title: "",
    Link: "",
    ImagePath: "",
    Image: "",
    Active: false,
    Rank: 0,
  });
  const [visitors, setVisitors] = useState(0);
  useEffect(() => {
    VISITORS().then((e) => {
      setVisitors(e);
    });
  }, []);
  return (
    <div className="content">
      <h2>Visitors : {visitors}</h2>
      <HomeCategories />
      <CrudComponent
        GET={INVENTORY}
        INSERT={INVENTORY_INSERT}
        UPDATE={INVENTORY_UPDATE}
        DELETE={INVENTORY_DELETE}
        ORDER={INVENTORY_ORDER}
        defaultValues={defaultValuesPartners}
        CardComponent={PartnersCard}
        ElementForm={PartnersForm}
        Key="Id"
        title="Partner"
      />
    </div>
  );
}

export default Home;
