import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Contact from "../AboutUs/Components/contact";
const ContactUs = () => {
  const { t, i18n } = useTranslation();

  useEffect(async () => {
    window.scrollTo(0, 0);
  }, []);
  let history = useHistory();

  return (
    <>
      <Contact />
    </>
  );
};

export default ContactUs;
