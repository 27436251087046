import { Button, Modal } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import ComponentHeader from "../../../components/ComponentHeader/ComponentHeader";
const AppWorks = ({
  section,
  Video = "https://www.youtube.com/watch?v=4aVSqeXS6oo",
}) => {
  const { t, i18n } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      <div id="/home#works" className="block worksBlock">
        <div
          style={{
            textAlign: "center",
            zIndex: "10",
            position: "inherit",
          }}
        >
          <ComponentHeader
            {...{
              Title: section[0]?.Content,
              TitleEn: section[0]?.ContentEn,
              Description: section[1]?.Content,
              DescriptionEn: section[1]?.ContentEn,
            }}
          />
        </div>

        <div className="container-fluid" style={{ color: "white" }}>
          <div className="contentHolder">
            <Button onClick={showModal}>
              <i className="fas fa-play"></i>
            </Button>
          </div>
        </div>
        <Modal
          visible={isModalVisible}
          footer={null}
          onCancel={handleCancel}
          width={"50%"}
        >
          <ReactPlayer width={"100%"} url={Video} />
        </Modal>
      </div>
    </>
  );
};

export default AppWorks;
