import React from "react";
import { useTranslation } from "react-i18next";
import { FormGroup, Input } from "reactstrap";
const LabeledInput = ({ Label, value, HandleChange, type, name }) => {
  const { t } = useTranslation();
  return (
    <FormGroup>
      <label style={{ fontSize: "14px" }}>{t(Label)}</label>
      <Input
        id={name}
        value={value}
        onChange={(e) => HandleChange(e.target.value, e.target.id)}
        type={type}
        style={{ fontSize: "18px" }}
      />
    </FormGroup>
  );
};

export default LabeledInput;
