import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useHistory, useLocation, useParams } from "react-router-dom";
import ComponentHeader from "../../components/ComponentHeader/ComponentHeader";
import ElementCard from "../../components/ElementCard/ElementCard";
import { categories } from "../../Mock";
import {
  ITEMS,
  ITEMS_USER,
  SUPER_CATEGORIES_CATEGORIES_USER,
} from "../../Admin/Items/Items.Api";
import Loader from "../../components/Loader/Loader";
const Category = () => {
  const { t, i18n } = useTranslation();
  useEffect(() => {}, []);

  const [data, setdata] = useState({});
  const [loading, setloading] = useState(false);
  let { id } = useParams();
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    setloading(true);
    console.log(location);
    if (location.pathname.includes("super-category")) {
      SUPER_CATEGORIES_CATEGORIES_USER(id)
        .then((res) => {
          setdata(res);
        })
        .catch(() => {})
        .finally(() => setloading(false));
    } else {
      ITEMS_USER(id)
        .then((res) => {
          setdata(res);
        })
        .catch(() => {})
        .finally(() => setloading(false));
    }
  }, [id, location]);
  let history = useHistory();

  return (
    <>
      <div
        className="main"
        style={{ direction: i18n.language === "ar" ? "rtl" : "ltr", margin: 0 }}
      >
        <Loader loading={loading} />
        <ComponentHeader {...data} />
        <div
          className="row"
          style={{
            margin: 0,
          }}
        >
          {location.pathname.includes("super-category")
            ? data?.Categories?.map((e) => (
                <ElementCard
                  {...e}
                  onClick={() => history.push("/category/" + e.Id)}
                  category={true}
                />
              ))
            : data?.Items?.map((e) => (
                <ElementCard
                  {...e}
                  onClick={() => history.push("/item/" + e.Id)}
                  category={true}
                />
              ))}
        </div>
      </div>
    </>
  );
};

export default Category;
