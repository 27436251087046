import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { userLogin, user_selector } from "../../store/AuthReducer";
import "./Login.css";

import { FormGroup } from "reactstrap";
import ButtonComponent from "../../components/ButtonComponent/ButtonComponent";
import { CheckBox } from "../../components/Inputs";
import LabeledInput from "../../components/Inputs/LabeledInput";
function Login() {
  let dispatch = useDispatch();
  let selector = useSelector(user_selector);
  let history = useHistory();

  const [values, setvalues] = useState({
    email: "",
    password: "",
    rememberMe: false,
  });

  useEffect(() => {
    if (Object.keys(selector).length > 0) {
      history.push("/admin");
    }
  }, [selector]);
  let HandleChange = useCallback((value, id) => {
    setvalues((prev) => ({ ...prev, [id]: value }));
  }, []);
  let Submit = useCallback(
    async (e) => {
      //   e.preventDefault();
      dispatch(await userLogin(values));
    },
    [values, history]
  );

  const { t } = useTranslation();

  return (
    <div className="loginframe">
      <label
        className="sign"
        style={{ fontSize: "20px", textAlign: "center", width: "100%" }}
      >
        {t("Sign In")}
      </label>
      <form onSubmit={Submit}>
        <FormGroup>
          <LabeledInput
            Label={"Username"}
            HandleChange={HandleChange}
            name={"email"}
            value={values?.email}
            type="text"
          />
        </FormGroup>
        <FormGroup>
          <LabeledInput
            Label={"Password"}
            HandleChange={HandleChange}
            name={"password"}
            value={values?.password}
            type="password"
          />
        </FormGroup>

        <FormGroup>
          <CheckBox
            label={t("Remember me")}
            value={values?.rememberMe || false}
            name="rememberMe"
            handleChange={HandleChange}
          />
        </FormGroup>
        <ButtonComponent onClick={Submit} title="Sign In" pressOnEnter />
      </form>

      {/* <p class="forgot" align="center">
        <Link to="/forget-password" className="label-shared-style">
          {t("Forgot Password?")}
        </Link>
      </p> */}
    </div>
  );
}

export default Login;
