import { Layout } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ALL } from "../../services/SharedApi/Section.Api";
import AppHome from "./Components/home";
import Loader from "../../components/Loader/Loader";
import temp from "../../assets/temp.png";
const { Header } = Layout;

function AboutUs() {
  const { t, i18n } = useTranslation();
  const [data, setdata] = useState({});
  const [loading, setloading] = useState(false);
  useEffect(async () => {
    setloading(true);
    //  if (!document.documentElement.getAttribute("lang")) {
    ALL()
      .then((res) => {
        setdata(res);
      })
      .catch((err) => console.log(err))
      .finally((e) => setloading(false));
  }, []);

  return (
    <div>
      <Loader loading={loading} />
      {data && <AppHome data={data} />}
    </div>
  );
}

export default AboutUs;
