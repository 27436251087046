import { Anchor, Button, Carousel, Col, Row } from "antd";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ApiBaseUrl } from "../../../services/config";
import { useTranslation } from "react-i18next";
import ComponentHeader from "../../../components/ComponentHeader/ComponentHeader";

import placeholder from "../../../assets/placeholder.png";
const { Link } = Anchor;

const AppHero = ({ data }) => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <div id="/home#hero" className="heroBlock  ">
        <ComponentHeader
          {...{
            Title: "كيف نعمل؟",
            TitleEn: "How we work?",
            Description: "",
            DescriptionEn: "",
          }}
        />

        <Carousel autoplay autoplaySpeed={3000} effect="fade">
          {data?.map((item, index) => {
            return (
              <div key={item.key}>
                <Row
                  justify="center"
                  style={{
                    backgroundColor: "black",
                    background: `url(${
                      item.ImagePath
                        ? ApiBaseUrl + item.ImagePath?.replaceAll("\\", "//")
                        : placeholder
                    }) no-repeat`,
                    // background: `url(${data.ImagePath}) no-repeat`,
                    backgroundSize: "cover",
                  }}
                  gutter={[16, 16]}
                >
                  <Col
                    style={{
                      backgroundColor: "#000000ab",
                      color: "white",
                      direction: i18n.language === "ar" ? "rtl" : "ltr",
                    }}
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        flexDirection: "column",
                        padding: "20px",
                        maxWidth: "1200px",
                        margin: "auto",
                      }}
                    >
                      <h3
                        style={{
                          color: "white",
                        }}
                      >
                        {i18n.language === "en" ? item.TitleEn : item.Title}
                      </h3>
                      <p>
                        {i18n.language === "en"
                          ? item.DescriptionEn
                          : item.Description}
                      </p>
                      {/*item.TempPostId > 0 ? (
                        <div className="btnHolder">
                          <a
                            onClick={(e) => false}
                            href={"#/postDetails/" + item.TempPostId}
                          >
                            <Button
                              type="primary"
                              style={{
                                backgroundColor: "#0fb89f",
                                borderColor: "#0fb89f",
                              }}
                              size="large"
                            >
                              {t("Learn More")}
                            </Button>
                          </a>
                        </div>
                            ) : null*/}
                    </div>
                  </Col>
                </Row>
                {/*                 <div className="btnHolder">
                    <Button type="primary" size="large">
                      {t("Learn More")}
                    </Button>
                    <Button size="large">
                      <i className="fa fa-desktop"></i>
                      {t("Watch a Demo")}
                    </Button>
                  </div>*/}
              </div>
            );
          })}
        </Carousel>
      </div>
    </>
  );
};

export default React.memo(AppHero);
