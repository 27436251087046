import React from "react";
import { useTranslation } from "react-i18next";
import { FaWhatsapp } from "react-icons/fa";

const FloatingButton = () => {
  const { t, i18n } = useTranslation();
  return (
    <div
      style={{
        position: "fixed",
        bottom: "20px",
        left: i18n.language == "en" && "20px",
        right: i18n.language == "ar" && "20px",
        width: "60px",
        height: "60px",
        borderRadius: "50%",
        backgroundColor: "#25ce46",
        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.25)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#ffffff",
        fontSize: "30px",
        zIndex: 100,
        cursor: "pointer",
      }}
    >
      <FaWhatsapp
        style={{ fontSize: "30px" }}
        onClick={() =>
          window.open("https://wa.me/+966504456441?text=RPF.", "_blank").focus()
        }
      />
    </div>
  );
};

export default FloatingButton;
