import React from "react";
// javascript plugin used to create scrollbars on windows
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import Navbar from "../components/Navbar/Navbar";
import SideBar from "../components/SlideBar/Sidebar";
import { user_selector } from "../store/AuthReducer";
import { routes } from "./Routes";
const AdminLayout = (props) => {
  let history = useHistory();
  let dispatch = useDispatch();
  let selector = useSelector(user_selector);
  require("../css/paper-dashboard.css");
  const mainPanel = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    if (Object.keys(selector).length === 0) {
      history.push("/admin/log-in");
    } else {
      axios.defaults.headers.Authorization = `bearer ${selector.token}`;
    }
    mainPanel.current && (mainPanel.current.scrollTop = 0);
    document.scrollingElement && (document.scrollingElement.scrollTop = 0);
  }, [location]);
  return (
    <div className="wrapper">
      <SideBar
        {...props}
        routes={routes}
        bgColor={"black"}
        activeColor={"primary"}
      />
      <div className="main-panel" ref={mainPanel}>
        <Navbar {...props} />

        <Switch>
          {routes.map((prop, key) => {
            // prop.name != 'Logout' ?
            return (
              <Route
                path={prop.layout + prop.path}
                component={prop.component}
                exact
                key={key}
              />
            );
          })}
        </Switch>
      </div>
    </div>
  );
};

export default AdminLayout;
