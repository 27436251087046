import "bootstrap/dist/js/bootstrap.bundle";
import "devextreme/dist/css/dx.light.css";
import "font-awesome/css/font-awesome.min.css";
import "popper.js/dist/popper";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import { Route, Switch } from "react-router-dom";

import "./App.css";
import AdminAuth from "./layout/AdminAuth";
import UserLayout from "./layout/UserLayout";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { initGA, logPageView } from "./components/analytics";
import { VISITORS_INC } from "./Admin/Home/Home.Api";

function App() {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    initGA();
    logPageView();
    VISITORS_INC();
  }, []);

  return (
    <div
      style={{ direction: i18n.language === "en" ? "ltr" : "rtl" }}
      lang={i18n.language === "en" ? "en" : "ar"}
    >
      <Switch>
        <Route path="/admin" component={AdminAuth} />
        <Route path="/" component={UserLayout} />
      </Switch>
    </div>
  );
}

export default App;
