import React from "react";

import axios from "axios";
import ButtonComponent from "../ButtonComponent/ButtonComponent";
import { useState } from "react";
import {
  OBJModel,
  GLTFModel,
  AmbientLight,
  DAEModel,
  DirectionLight,
} from "react-3d-viewer";
import { ApiBaseUrl } from "../../services/config";
export default function ThreeDModel({ modelPath }) {
  const height = window.innerHeight;
  const width = window.innerWidth;
  const [open, setOpen] = useState(false);

  return (
    <div>
      {modelPath && !open && (
        <div style={{ maxWidth: "300px", height: "70px" }}>
          <ButtonComponent
            onClick={() => setOpen(true)}
            title="Display 3D model"
          />
        </div>
      )}

      {open && (
        <div
          style={{
            widows: "100vw",
            height: "100vh",
            position: "fixed",
            zIndex: 20,

            top: 0,
            left: 0,
          }}
        >
          <div onClick={() => setOpen(false)}>
            <a
              style={{
                position: "absolute",
                left: 20,
                right: 20,
                color: "black",
                fontWeight: "700",
                fontSize: "20px",
                zIndex: 10,
              }}
              className="fas fa-xmark"
            ></a>
          </div>

          <div>
            <OBJModel
              height={height}
              position={{ x: 0, y: 0, z: 0 }}
              scale={{ x: 0.5, y: 0.5, z: 0.5 }}
              src={ApiBaseUrl + modelPath}
              width={width}
            />
          </div>
        </div>
      )}
    </div>
  );
}
