import React from "react";
import { useTranslation } from "react-i18next";

const ComponentHeader = ({ Title, TitleEn, Description, DescriptionEn }) => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <div style={{ textAlign: "center", textTransform: "uppercase" }}>
        <h2
          style={{
            fontSize: "48px",
            fontFamily: i18n.language == "en" && "monotest",
            marginTop: "30px",
          }}
        >
          {i18n.language === "ar" ? Title : TitleEn}
        </h2>
        <p>{i18n.language === "ar" ? Description : DescriptionEn}</p>
      </div>
    </>
  );
};

export default ComponentHeader;
