import { Draggable } from "react-drag-reorder";

export default function ReOrderList({
  data,
  Card,
  onPosChange,
  passed,
  order = true,
}) {
  return (
    <div className="flex-container">
      <div>
        {passed ? (
          <Draggable onPosChange={onPosChange}>
            {data.map((ele, idx) => {
              return (
                <div key={idx}>
                  <Card res={ele} {...passed} index={idx} />
                </div>
              );
            })}
          </Draggable>
        ) : (
          <>
            {data.map((ele, idx) => {
              return (
                <div key={idx}>
                  <Card res={ele} {...passed} index={idx} />
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
}
