import { Redirect, Route, Switch } from "react-router-dom";
import AdminLayout from "./AdminLayout";
import Login from "../Admin/LogIn/Login";
import ForgetPassword from "../Admin/ForgetPassword/ForgetPassword";
import { GetFromLocalStorage } from "../services/localStorageService";

import { useDispatch } from "react-redux";
import { useEffect } from "react";
import "../Admin/LogIn/Login.css";
import { userLoginLocalStorage } from "../store/AuthReducer";
function AdminAuth() {
  const dispatch = useDispatch();

  useEffect(() => {
    GetFromLocalStorage("user-auth") &&
      dispatch(userLoginLocalStorage(GetFromLocalStorage("user-auth")));
  }, []);

  return (
    <div className="App">
      <Switch>
        <Route path="/admin/log-in" component={Login} />
        {/* <Route path="/admin/forget-password" component={ForgetPassword} /> */}
        <Route path="/admin" component={AdminLayout} />
        <Redirect to="/admin/log-in" />
      </Switch>
    </div>
  );
}

export default AdminAuth;
