import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useHistory, useParams } from "react-router-dom";
import ComponentHeader from "../../components/ComponentHeader/ComponentHeader";
import ElementCard from "../../components/ElementCard/ElementCard";
import { categories } from "../../Mock";
import { CATEGORIES } from "../../Admin/Categories/Categories.Api";
import { CATEGORIES_USER } from "../../Admin/Home/Home.Api";
import Loader from "../../components/Loader/Loader";
const Categories = () => {
  const { t, i18n } = useTranslation();
  useEffect(() => {}, []);

  const [loading, setloading] = useState(false);
  const [data, setdata] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    setloading(true);
    CATEGORIES_USER()
      .then((res) => {
        setdata(res);
      })
      .catch(() => {})
      .finally((e) => setloading(false));
  }, []);
  let history = useHistory();

  return (
    <>
      <div
        className="main"
        style={{ direction: i18n.language === "ar" ? "rtl" : "ltr", margin: 0 }}
      >
        <Loader loading={loading} />
        <div
          className="row"
          style={{
            margin: 0,
          }}
        >
          {data.map((e) => (
            <ElementCard
              {...e}
              onClick={() => history.push("/item/" + e.Id)}
              category={true}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default Categories;
