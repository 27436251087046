import React, { memo, useCallback, useRef, useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { BiImageAdd } from "react-icons/bi";

import { useTranslation } from "react-i18next";
import ButtonComponent from "../ButtonComponent/ButtonComponent";

const UploadImageButton = ({
  isMultiple,
  handleGetImages,
  handleRemoveImage,
  handleRemoveAllImages,
  imagesFiles,
  required = false,
  title = "Upload image",
  accept = "image/*",
}) => {
  const { t, i18n } = useTranslation();
  const fileInput = useRef();

  return (
    <div className="col-12 ">
      <div
        className="inputField"
        style={{
          direction: i18n.language === "en" ? "ltr" : "rtl",
        }}
      >
        <input
          ref={fileInput}
          accept={accept}
          type={"file"}
          required={required}
          className={"d-none"}
          onClick={(event) => {
            const element = event.target;
            element.value = "";
          }}
          onChange={(event) => handleGetImages(event)}
          multiple={isMultiple}
        />

        <div className="w-100 d-flex align-items-center  ">
          <ButtonComponent
            onClick={() => fileInput.current.click()}
            title={title}
            icon={"fas fa-images"}
          />
          {imagesFiles.length > 0 ? (
            <span className="clearAllOption" onClick={handleRemoveAllImages}>
              {isMultiple ? t("Clear All?") : ""}
            </span>
          ) : (
            ""
          )}
        </div>

        {imagesFiles.length > 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              width: "100%",
              minHeight: "100px",
              marginTop: "15px",
              gap: "20px",
              flexWrap: "wrap",
              position: "relative",
            }}
          >
            {accept == "image/*"
              ? imagesFiles.map((element) => {
                  return (
                    <div
                      style={{
                        width: "200px",
                        height: "200px",
                        position: "relative",
                      }}
                    >
                      <AiFillCloseCircle
                        style={{
                          position: "absolute",
                          top: "5%",
                          right: "3%",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleRemoveImage && handleRemoveImage(element)
                        }
                      />

                      <img
                        alt={"img"}
                        style={{
                          width: "auto",
                          height: "auto",
                          maxHeight: "200px",
                        }}
                        src={
                          typeof element == "string"
                            ? element
                            : URL.createObjectURL(element)
                        }
                      />
                    </div>
                  );
                })
              : imagesFiles.map((element) => {
                  return (
                    <div>
                      {typeof element == "string" ? element : element?.name}
                    </div>
                  );
                })}
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(UploadImageButton);
