import React, { useState } from "react";
import { FormGroup } from "reactstrap";

import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CheckBox, SelectBox } from "../../../../components/Inputs";
import { CATEGORIES } from "../../Home.Api";
import Loader from "../../../../components/Loader/Loader";

function HomeCategoriesForm({
  data,
  HandleChange,
  handleGetImages,
  handleRemoveImage,
  visible,
}) {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    CATEGORIES()
      .then((e) => setCategories(e))
      .finally(() => setLoading(false));
  }, []);
  const { t } = useTranslation();
  return !loading ? (
    <div style={{ width: "100%" }}>
      <FormGroup>
        <SelectBox
          label={t("Category")}
          dataSource={categories}
          keys={{ id: "Id", name: "Title", nameEn: "TitleEn" }}
          value={data?.CategoryId}
          name="CategoryId"
          handleChange={HandleChange}
        />
      </FormGroup>
      <div className="row">
        <div className="col">
          <FormGroup>
            <CheckBox
              label={t("Active")}
              value={data?.Active || false}
              name="Active"
              handleChange={HandleChange}
            />
          </FormGroup>
        </div>
      </div>
    </div>
  ) : (
    <Loader loading={true} />
  );
}

export default React.memo(HomeCategoriesForm);
