import { Layout } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Loader from "../../../components/Loader/Loader";

import HomeCategories from "./Components/HomeCategories";
import Partners from "../../AboutUs/Components/Partners";
import AppContact from "../../AboutUs/Components/contact";
import { CATEGORIES_AND_PARTENERS } from "./HomeApi";
const { Header } = Layout;

function Home() {
  const { t, i18n } = useTranslation();
  const [data, setdata] = useState(null);
  const [loading, setloading] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    setloading(true);
    CATEGORIES_AND_PARTENERS()
      .then((res) => {
        setdata(res);
        setloading(false);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <div>
      <Loader loading={loading} />
      {data && (
        <>
          <HomeCategories data={data?.HomeCateogry} />
          <Partners data={data?.Parteners} />
          <AppContact data={data?.Branches} />
        </>
      )}
    </div>
  );
}

export default Home;
