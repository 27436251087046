import AboutUs from "../Admin/AboutUs/AbourUs";
import ContactUs from "../Admin/ContactUs/ContactUs";
import FAQ from "../Admin/FAQ";
import Categories from "../Admin/Categories/Categories";
import Home from "../Admin/Home/Home";
import Items from "../Admin/Items/Items";
import Footer from "../Admin/Footer";
const layout = "/admin";
export const routes = [
  {
    path: "/home",
    name: "Home",
    icon: "fas fa-home",
    component: Home,
    layout: layout,
  },
  {
    path: "/categories",
    name: "Categories",
    icon: "fab fa-elementor",
    component: Categories,
    layout: layout,
  },
  {
    path: "/items",
    name: "Items",
    icon: "fas fa-images",
    component: Items,
    layout: layout,
  },
  // {
  //   path: "/item-files",
  //   name: "Item Files",
  //   icon: "fas fa-images",
  //   component: FAQ,
  //   layout: layout,
  // },

  {
    path: "/about-us",
    name: "About us",
    icon: "fas fa-address-card",
    component: AboutUs,
    layout: layout,
  },
  {
    path: "/FAQ",
    name: "FAQ",
    icon: "fas fa-solid fa-question",
    component: FAQ,
    layout: layout,
  },
  /* {
    path: "/posts",
    name: "Posts",
    icon: "fas fa-newspaper",
    component: Posts,
    layout: layout,
  },*/
  {
    path: "/contact-us",
    name: "Contact us",
    icon: "far fa-address-book",
    component: ContactUs,
    layout: layout,
  },
  {
    path: "/footer",
    name: "footer",
    icon: "fas fa-download",
    component: Footer,
    layout: layout,
  },
];
