import REQUEST from "../../services/Request";

export const CATEGORIES = async () => {
  return await REQUEST({
    method: "get",
    url: "Category/admin",
  });
};
export const CATEGORIES_USER = async () => {
  return await REQUEST({
    method: "get",
    url: "Category",
  });
};
export const HOME_CATEGORIES_SUBMIT = async (e) => {
  return await REQUEST({
    method: "post",
    url: "HomeCategories/Order",
    data: e,
  });
};
export const HOME_CATEGORIES_ADMIN = async (data) => {
  return await REQUEST({
    method: "get",
    url: "HomeCategories/admin",
  });
};
export const HOME_CATEGORIES_INSERT = async (data) => {
  return await REQUEST({
    method: "post",
    url: "HomeCategories",
    data,
  });
};
export const HOME_CATEGORIES_UPDATE = async (data) => {
  return await REQUEST({
    method: "put",
    url: "HomeCategories",
    data,
  });
};
export const HOME_CATEGORIES_DELETE = async (id) => {
  return await REQUEST({
    method: "delete",
    url: "HomeCategories/" + id,
  });
};
export const HEADER = async () => {
  return await REQUEST({
    method: "get",
    url: "Header/admin",
  });
};

export const HEADER_INSERT = async (e) => {
  return await REQUEST({
    method: "post",
    url: "Header",
    data: e,
  });
};
export const HEADER_ORDER = async (e) => {
  return await REQUEST({
    method: "post",
    url: "Header/Order",
    data: e,
  });
};
export const HEADER_UPDATE = async (e) => {
  return await REQUEST({
    method: "put",
    url: "Header",
    data: e,
  });
};
export const HEADER_DELETE = async (id) => {
  return await REQUEST({
    method: "delete",
    url: "/Header/" + id,
  });
};

export const BENEFITS = async (e, status) => {
  return await REQUEST({
    method: "get",
    url: "/Benefits/admin",
  });
};
export const BENEFITS_INSERT = async (e) => {
  return await REQUEST({
    method: "post",
    url: "Benefits",
    data: e,
  });
};
export const BENEFITS_UPDATE = async (e) => {
  return await REQUEST({
    method: "put",
    url: "Benefits",
    data: e,
  });
};
export const BENEFITS_DELETE = async (id) => {
  return await REQUEST({
    method: "delete",
    url: "Benefits/" + id,
  });
};
export const MEMBER = async (e, status) => {
  return await REQUEST({
    method: "get",
    url: "/Member/admin",
  });
};
export const MEMBER_INSERT = async (e) => {
  return await REQUEST({
    method: "post",
    url: "Member",
    data: e,
  });
};
export const MEMBER_ORDER = async (e) => {
  return await REQUEST({
    method: "post",
    url: "Member",
    data: e,
  });
};
export const MEMBER_UPDATE = async (e) => {
  return await REQUEST({
    method: "put",
    url: "Member",
    data: e,
  });
};
export const MEMBER_DELETE = async (id) => {
  return await REQUEST({
    method: "delete",
    url: "Member/" + id,
  });
};
export const VISITORS = async (id) => {
  return await REQUEST({
    method: "get",
    url: "Visitor",
  });
};
export const VISITORS_INC = async (id) => {
  return await REQUEST({
    method: "post",
    url: "Visitor",
  });
};
