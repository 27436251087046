import { Card, Col, Row } from "antd";
import React from "react";
import Meta from "antd/lib/card/Meta";
import { ApiBaseUrl } from "../../../services/config";
import { useTranslation } from "react-i18next";
import ComponentHeader from "../../../components/ComponentHeader/ComponentHeader";

const Partners = ({ data }) => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <div className="block aboutBlock ">
        <div className="container-fluid">
          <ComponentHeader
            {...{
              Title: "شركاؤنا",
              TitleEn: "Partners",
              Description: "",
              DescriptionEn: "",
            }}
          />

          <div
            style={{ display: "flex", justifyContent: "center" }}
            className="animate__animated animate__zoomIn"
          >
            {data?.map((item, index) => {
              return (
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <img
                    src={
                      item?.ImagePath
                        ? ApiBaseUrl + item?.ImagePath
                        : placeholder
                    }
                    className="hover-opacity"
                    style={{ cursor: "pointer", width: "100%", padding: 5 }}
                    //  src={item?.ImagePath}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(Partners);
