import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./Loader.css";
function Loader({ loading }) {
  const { t, i18n } = useTranslation();

  return loading ? (
    <div className="fullscreen">
      <div
        className="loader_x_form spinner-border "
        style={{
          color: "black",
        }}
        role="status"
      >
        <span
          style={{
            color: "black",
          }}
          className="sr-only"
        >
          Loading...
        </span>
      </div>
    </div>
  ) : null;
}

export default React.memo(Loader);
