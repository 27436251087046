import React, { useEffect, useRef } from "react";
import "./ButtonComponent.css";
import { TailSpin } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import { Button } from "devextreme-react";
import useKeyboardShortcut from "use-keyboard-shortcut";
import { createRef } from "react";
import useEnterKeyListener from "./useEnterKeyListener";
const ButtonComponent = ({
  title,
  onClick,
  icon,
  disabled = false,
  useSubmitBehavior = false,
  shortCut = ["Enter"],
  allowShortCut = false,
  pressOnEnter,
  hint,
}) => {
  const { t, i18n } = useTranslation();
  const refBtn = useRef();

  useEnterKeyListener({
    querySelectorToExecuteClick: "submitButton",
    enable: pressOnEnter,
  });
  return (
    <div style={{ margin: "5px 5px" }}>
      <Button
        width={"100%"}
        text={" " + t(title) + " "}
        type="normal"
        stylingMode="outlined"
        onClick={onClick}
        icon={icon}
        style={{ fontSize: 25 }}
        id={pressOnEnter && "submitButton"}
        useSubmitBehavior={useSubmitBehavior}
        disabled={disabled}
        hint={hint}
        ref={refBtn}
      />
    </div>
  );
};

export default React.memo(ButtonComponent);
